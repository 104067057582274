import * as tiles from "./wasm/tiles.js"

window["tiles"] = tiles;

window.onerror = e => {
	if (e == "Script error.") return;

	console.error(e);
	alert(`Fatal error: ${e.message} at ${e.filename}:${e.lineno}.\n\n${e.stack}`);
};

await tiles.default();

tiles.set_panic_hook();

let game = tiles.Controller.new(
	document.getElementById("main"),
	document.body.dataset.api);

window["game"] = game;
